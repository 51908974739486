/* home-news.css */
.home-news2 {
  width: 100%;
}

.home-news2 .news-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3%;
}

.home-news2 .news-block {
  background: #fff;
  position: relative;
  height: auto;
}

.home-news2 .nb-large {
  flex: 1 1 48.5%;
  max-width: 48.5%;
}

.home-news2 .nb-small {
  flex: 1 1 31.16%;
  max-width: 31.16%;
}

.home-news2 a {
  text-decoration: none;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.home-news2 .news-card:hover img,
.home-news2 .news-card:focus img {
  transform: scale(1.05) translateZ(0);
}

.home-news2 .news-body {
  padding: 10px;
}

.home-news2 h3 {
  color: var(--primary);
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5em;
  width: 100%;
  overflow: hidden;
}

.home-news2 .category {
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--tertiary);
  color: #fff;
  z-index: 100;
  border: none;
  border-bottom-left-radius: 5px;
}

.home-news2 .category a {
  display: block;
  color: #fff;
  font-size: 14px;
  padding: 8px 15px;
}

.home-news2 .category a:hover,
.home-news2 .category a:focus {
  text-decoration: underline;
}

/* media queries */
@media (min-width: 480px) and (max-width: 799.98px) {
  .home-news2 .nb-small h3 {
    font-size: 14px;
  }
}

@media (min-width: 480px) and (max-width: 599.98px) {
  .home-news2 .nb-large h3 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .home-news2 .news-row {
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  .home-news2 .news-block {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
