/* news-card.css */

.news-card-block {
  flex-wrap: wrap;
}

.white-block .news-card {
  box-shadow: 0 0 16px rgb(0 0 0 / 20%);
}

.ldc-guides .content-flex .news-card-wrapper {
  padding: 16px;
  flex: 1 1 100%;
  max-width: 100%;
  display: flex;
}

.news-card {
  background: #fff;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  transition: transform .2s;
  overflow: hidden;
}

.reviewsio-widget .news-card {
  border: 1px solid var(--primary);
}

.news-card:not(.nozoom):hover {
  transform: scale(1.04);
}

.news-card:hover .cta {
  background: var(--primary);
}

.news-card a {
  color: inherit;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.news-card a:hover,
.news-card a:focus {
  text-decoration: none;
  color: inherit;
}

.news-card-body {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
}

.news-card-section {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
}

.news-card-section > * {
  flex: 0 0 auto;
}

.news-card-section .reviewsio-card-review {
  flex-grow: 1;
}

.news-card-title {
  font-size: 18px;
  flex-grow: 1;
}

.news-card .cta {
  font: 600 16px / normal "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  background: var(--secondary);
  color: #fff;
  padding: 16px;
  transition: background-color 0.2s;
  text-decoration: none;
}

.news-card .cta:hover,
.news-card .cta:focus {
  background: var(--primary);
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .ldc-guides .content-flex .news-card-wrapper {
    padding: 16px;
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
  }

  .ldc-guides .content-flex .news-card-wrapper:first-of-type {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 959.98px) {
  .ldc-guides .content-flex .news-card-wrapper {
    padding: 16px;
    flex: 1 1 33.33333%;
    max-width: 33.33333%;
    display: flex;
  }
}

@media (min-width: 960px) and (max-width: 1169.98px) {
  .ldc-guides .content-flex .news-card-wrapper {
    padding: 16px;
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
  }

  .ldc-guides .content-flex .news-card-wrapper:first-of-type {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

@media (min-width: 1170px) {
  .ldc-guides .content-flex .news-card-wrapper {
    padding: 16px;
    flex: 1 1 33.33333%;
    max-width: 33.33333%;
    display: flex;
  }
}
