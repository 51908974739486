.purple-block .usp {
  margin-top: 40px;
}

.usp-container {
  background: #fff;
  user-select: none;
  overflow: hidden;
}

.purple-block .usp-container {
  background: transparent;
}

#alldeals .usp-container {
  padding: 20px 16px;
  margin-bottom: 40px;
}

.usp-carousel {
  display: flex;
}

.usp-carousel .usp-item {
  margin: 30px 2px 0;
}

.usp-carousel:not(.init) {
  position: relative;
  flex-wrap: nowrap;
  margin-right: -16px;
}

.usp-carousel:not(.init) .usp-item {
  padding-right: 16px;
  flex: 0 0 calc(100% - 4px);
  height: auto;
  display: flex;
  position: relative;
}

.usp-carousel.init .usp-item {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  transition: none;
  position: relative;
  text-align: center;
}

.usp-carousel .usp-body {
  border: 1px solid var(--primary);
  text-align: left;
  padding: 30px 15px 15px;
  border-radius: 10px;
  flex-grow: 1;
  max-width: calc(100% - 1px);
  transition: none;
  margin-bottom: 2px;
}

.purple-block .usp-carousel .usp-body {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.usp-carousel .usp-item > i {
  position: absolute;
  width: 54px;
  height: 54px;
  background: #fff;
  border-radius: 27px;
  top: -27px;
  margin: 0 auto;
  left: 0;
  right: 0;
  font-size: 48px;
}

.usp-carousel .usp-item > i.size2 {
  font-size: 36px;
}

.usp-carousel .usp-text-wrap {
  display: inline-block;
  width: 100%;
  white-space: normal;
  text-align: center;
}

.usp-carousel .usp-title {
  font-size: 16px;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  margin-bottom: 5px;
}

.usp-carousel .usp-text {
  font-size: 16px;
  color: var(--primary);
  font-weight: normal;
}

.purple-block .usp-carousel .usp-title,
.purple-block .usp-carousel .usp-text {
  color: #fff;
}

.usp-carousel .usp-text > span {
  white-space: nowrap;
  display: inline-block;
}

.usp-carousel .usp-text i {
  font-size: 16px;
  display: inline-block;
  width: auto;
  margin-left: 0.25em;
}

.usp-carousel .ldc:before,
.usp-carousel .ldc:after {
  display: flex;
  justify-content: center;
  align-items: center;
}

.usp-carousel .size2 .ldc:before,
.usp-carousel .size2 .ldc:after {
  transform: translateX(1px)
}

/* media queries */
@media (min-width: 576px) {
  .usp-carousel:not(.init) .usp-item {
    flex: 1 0 calc(50% - 4px);
  }
}

@media (min-width: 768px) {
  .usp-carousel:not(.init) .usp-item {
    flex: 1 0 calc(33.3333% - 4px);
  }
}
