/* homepage.css */
.guidetoleasing h2 {
  font-size: 24px;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #fff;
  padding-bottom: 20px;
}

.guidetoleasing a {
  width: 240px;
  margin: 0 auto 5px;
}

.guidetoleasing i.fa.fa-chevron-right {
  margin-left: 10px;
}

#home .reviewsio-widget {
  padding-bottom: 0;
}

.top-local .make {
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  font-size: 18px;
}

.top-local .model {
  color: var(--primary);
  font-size: 16px;
  margin-bottom: 10px;
}

.top-local p {
  font-size: 16px;
}

.toppicks-news > p {
  font-size: 16px;
}

.toppicks-news .news-card img {
  margin: 0 auto;
  width: 100%;
  max-width: 492px;
  height: auto;
}

.edge .toppicks-news .news-card img {
  pointer-events: none;
}

.why-lease .intro-video {
  max-width: 768px;
  margin: 0 auto;
}

.why-lease h2,
.comparison h2 {
  text-align: center;
}

.why-lease h2,
.comparison h2 {
  text-align: center;
}

.why-lease p,
.comparison p {
  font-size: 16px;
  text-align: center;
}

.comparison h2,
.comparison p {
  padding-left: 20px;
  padding-right: 20px;
}

.comparison p {
  padding-bottom: 30px;
}

.why-lease li,
.comparison li {
  margin-bottom: 20px;
}

.why-lease li > i,
.comparison li > i {
  font-size: 32px;
  display: inline-block;
  width: 32px;
  height: auto;
  vertical-align: top;
  margin-right: 5px;
  margin-top: -5px;
}

.why-lease li > .text-block,
.comparison li > .text-block {
  color: #fff;
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 48px);
}

.why-lease li > .text-block {
  color: var(--black);
}

.why-lease .icon-list,
.comparison .icon-list {
  margin: 10px 0 20px;
}

.comparison .col2 .image-wrap {
  max-width: 480px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.comparison .image-wrap img {
  width: 100%;
  height: auto;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.edge .comparison .image-wrap img {
  pointer-events: none;
}

.why-lease .cta,
.comparison .cta {
  max-width: 576px;
  margin: 0 auto;
}

/* media queries */
@media (min-width: 960px) {
  .top-local p {
    max-width: 66%;
  }
}

@media (min-width: 768px) {
  .why-lease .intro-video.mobile {
    display: none;
  }

  .why-lease .flex {
    display: flex;
    flex-direction: column;
    flex: 0 1 50%;
    padding-left: 40px;
  }

  .why-lease .col2:last-of-type h2 {
    text-align: center;
  }

  .why-lease h2,
  .why-lease p,
  .comparison h2,
  .comparison p {
    text-align: left;
  }

  .comparison h2,
  .comparison p {
    padding-left: 35px;
    padding-right: 35px;
  }

  .why-lease .flex-grid,
  .comparison .flex-grid {
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }

  .why-lease .col2,
  .comparison .col2 {
    flex: 1 1 50%;
  }

  .why-lease .col2:first-of-type,
  .comparison .col2:first-of-type {
    max-width: 576px;
  }

  .why-lease .col2:last-of-type, .comparison .col2:last-of-type {
    padding-left: 40px;
  }
}

@media (max-width: 767.98px) {
  .why-lease .intro-video.desktop {
    display: none;
  }

  .why-lease .flex {
    padding: 0;
    flex: 0 1 100%;
  }

  .why-lease .flex-grid .col2,
  .comparison .flex-grid .col2 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .comparison .flex-grid .col2:last-of-type {
    display: none;
  }

  .why-lease .flex-grid .col2:last-of-type {
    margin-top: 40px;
  }
}

@media (max-width: 359.98px) {
  #reviews-widget {
    margin-left: -25px;
    margin-right: -25px;
  }
}
