/* reviews.css */
.main-content {
  font-family: "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--black);
  padding-bottom: 1.5em;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}

.masonry-wrapper {
  padding-top: 1.5em;
}

.grid-sizer,
.masonry-brick {
  width: 31.333%;
}

.gutter-sizer {
  width: 2%;
}

.masonry-brick {
  background-color: #fff;
  display: block;
  vertical-align: top;
  border-radius: 15px;
}

.masonry-content {
  padding: 1.5em;
}

.loadMore {
  border: #3b2156 1px solid;
  background: transparent;
  color: var(--primary);
  margin: 0 auto;
  width: 250px;
  padding: 16px 0;
  font-weight: 600;
  clear: both;
}

.loadMore:hover,
.loadMore:focus {
  color: #fff;
}

.review-title,
.filters-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--primary);
  margin: 0 auto;
}

.review-title {
  float: left;
  text-transform: capitalize;
  max-width: 150px;
}

.filters-title {
  padding-top: 1rem;
  display: inline-block;
}

.review-title,
.review-description {
  margin: 0;
  margin-bottom: 10px;
}

.review-description {
  font-size: 0.75rem;
  white-space: pre-line;
}

.rating-stars {
  color: var(--stars);
  font-size: 20px;
}

.rating-stars-average {
  color: var(--stars);
  font-size: 28px;
  padding-right: 5px;
}

.review hr {
  border-top: 1px dotted;
}

.review .created {
  font-size: 0.75rem;
  float: right;
}

.review .verified {
  border: 1px solid #49d68b;
  color: #49d68b;
  padding: 0.25rem;
  width: 80px;
  text-align: center;
  text-transform: capitalize;
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

.reviews-summary {
  text-align: center;
  margin-bottom: 1.5em;
}

.reviews-summary a {
  text-decoration: none;
}

.filter-reviews {
  background: #fff;
  border-radius: 15px;
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
}

.filter-reviews .flex {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.5rem 2rem 0.5rem;
  width: 100%;
}

.filter-reviews .reset {
  float: right;
  padding-right: 1.1rem;
  margin-top: -1.5rem;
  text-decoration: underline;
  display: none;
}

.filter-reviews .filter {
  border: 2px #c0c1be solid;
  padding: 0.75rem 0.15rem;
  margin: 0 0.25rem;
  border-radius: 10px;
  flex: 1 1 10%;
  position: relative;
}

.filter-reviews .filter.active {
  border: 2px #3b2156 solid;
}

.filter-reviews .filter.inactive {
  opacity: 0.3;
}

.filter-reviews .filter:hover {
  cursor: pointer;
}

.reviews-summary .reviews-io-logo {
  display: inline-block;
  height: 26px;
  position: relative;
  padding-left: 5px;
  vertical-align: middle;
}

.go-back {
  float: left;
  color: var(--secondary);
  text-decoration: underline;
  font-size: 14px;
  padding-top: 25px;
  padding-bottom: 15px;
}

.go-back:before {
  color: var(--tertiary);
  font-size: 18px;
  margin-right: 10px;
  content: "\f053";
  font-family: "rgd-icons";
  font-style: normal;
  font-weight: normal;
  vertical-align: middle;
  display: inline-block;
}

.reviews-summary h1 {
  clear: both;
}

.filter-reviews .filter .count {
  margin-left: 0.3rem;
  height: 20px;
  vertical-align: middle;
}

.filter-reviews .toggle-filters {
  padding-bottom: 0.75rem;
}

.filter-reviews .fa-chevron-down,
.filter-reviews .fa-chevron-up {
  padding-left: 0.35em;
  color: var(--primary);
  position: relative;
  bottom: 2px;
  display: none;
}

.filter-reviews .count-suffix {
  display: none;
}

.no-reviews {
  background: #fff;
  width: 100%;
  min-height: 50px;
  border-radius: 10px;
  color: var(--primary);
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-size: 24px;
  margin-top: 25px;
  margin-bottom: 400px;
}

.loadMore .fa-spinner {
  margin-left: 10px;
  font-size: 22px;
}

.review .replies {
  clear: both;
  font-size: 0.75rem;
  padding-top: 20px;
}

.review .replies .reply-from {
  font-weight: 600;
}

.rating-stars {
  width: 118px;
  display: inline-block;
  position: relative;
  height: 20px;
  vertical-align: middle;
}

.rating-stars:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f006 \f006 \f006 \f006 \f006";
  font-size: 20px;
  font-family: rgd-icons;
  color: var(--stars);
  display: block;
  width: auto;
  height: 20px;
  z-index: 0;
  letter-spacing: 5px;
}

.rating-stars:after {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  font-family: rgd-icons;
  color: var(--stars);
  display: block;
  width: auto;
  height: 20px;
  z-index: 1;
  letter-spacing: 5px;
}

.r-stars-1:after {
  content: "\f005";
}

.r-stars-2:after {
  content: "\f005 \f005";
}

.r-stars-3:after {
  content: "\f005 \f005 \f005";
}

.r-stars-4:after {
  content: "\f005 \f005 \f005 \f005";
}

.r-stars-5::after {
  content: "\f005 \f005 \f005 \f005 \f005";
}

.r-stars-0-5:after {
  content: "\f089";
}

.r-stars-1-half::after {
  content: "\f005 \f123";
}

.r-stars-2-half::after {
  content: "\f005 \f005 \f123";
}

.r-stars-3-half::after {
  content: "\f005 \f005 \f005 \f123";
}

.r-stars-4-half::after {
  content: "\f005 \f005 \f005 \f005 \f123";
}

.r-stars-4-5:after {
  content: "\f005 \f005 \f005 \f005 \f089";
}

.reviews-summary .rating-stars {
  width: 128px;
  height: 22px;
}

.reviews-summary .rating-stars:before,
.reviews-summary .rating-stars:after {
  font-size: 22px;
}

.reviewsio-widget {
  padding: 15px;
  border-radius: 15px;
}

.reviewsio-widget .ns-carousel-card-section {
  padding: 15px 25px;
}

.reviewsio-widget .ns-carousel-card {
  border: 1px solid var(--primary);
}

.reviewsio-widget .see-all-btn {
  margin: 0 auto 5px;
  min-width: 200px;
  max-width: 100%;
  width: 320px;
  text-align: center;
}

.see-all-btn > i.fa.fa-chevron-right {
  margin-left: 10px;
}

.reviewsio-widget .ns-carousel-item {
  padding-left: 8px;
  padding-right: 8px;
}

.reviewsio-widget .reviewsio-verdict {
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  color: var(--primary);
  display: inline-block;
  line-height: 30px;
}

.reviewsio-widget .reviewsio-verdict .rating-stars {
  margin-left: 10px;
  width: auto;
  line-height: 30px;
  vertical-align: top;
  height: inherit;
}

.reviewsio-widget .reviewsio-verdict .rating-stars::before {
  position: relative;
  font-size: 20px;
  height: inherit;
}

.reviewsio-widget .reviewsio-verdict .rating-stars::after {
  font-size: 20px;
  height: inherit;
}

.reviewsio-widget .reviewsio-statistics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: var(--middark-grey);
}

.reviewsio-widget .reviewsio-statistics em {
  font-weight: 600;
  color: var(--primary);
  font-style: normal;
}

.reviewsio-widget .title {
  text-align: center;
}

.reviewsio-logo-wrap img {
  max-width: 140px;
  display: inline-block;
}

.reviewsio-card-reviewer {
  font-size: 16px;
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: var(--primary);
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.reviewsio-card-review {
  margin-top: 10px;
  font-size: 16px;
  color: var(--primary);
  margin-bottom: 10px;
  flex: 1 0 auto;
}

.reviewsio-card-meta {
  font-size: 12px;
  color: var(--middark-grey);
}

.reviewsio-widget .ns-carousel-controls {
  left: -15px;
  right: -15px;
}

/* media queries */
@media (min-width: 960px) {
  .masonry-brick {
    width: calc(33.333% - 20px);
    float: left;
    margin-bottom: 30px;
    margin-right: 30px;
  }

  .masonry-brick:nth-child(3n + 3) {
    margin-right: 0;
  }

  .masonry-brick:nth-child(3n + 4) {
    clear: both;
  }
}

@media (min-width: 600px) and (max-width: 959.98px) {
  .masonry-brick {
    width: calc(50% - 15px);
    float: left;
    margin-bottom: 30px;
  }

  .masonry-brick:nth-child(odd) {
    clear: both;
    margin-right: 30px;
  }
}

@media all and (min-width: 600px) {
  .reviews-summary .rating-stars {
    width: 156px;
    height: 30px;
  }

  .reviews-summary .rating-stars:before,
  .reviews-summary .rating-stars:after {
    font-size: 28px;
  }
}

@media all and (max-width: 959.98px) {
  .reviewsio-widget .ns-carousel-card-section {
    padding: 15px;
  }
}

@media all and (min-width: 600px) and (max-width: 959.98px) {
  .filter-reviews .flex {
    justify-content: center;
  }

  .filter-reviews .filter {
    flex: 1 1 30%;
    max-width: 30%;
    margin-bottom: 1rem;
  }

  .filter-1 {
    order: 3;
  }

  .filter-2 {
    order: 4;
  }

  .filter-3 {
    order: 0;
  }

  .filter-4 {
    order: 1;
  }

  .filter-5 {
    order: 2;
  }

  .gutter-sizer {
    width: 2%;
  }

  .main-content {
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (max-width: 599.98px) {
  .filter-reviews .fa-chevron-down,
  .filter-reviews .fa-chevron-up {
    display: inline-block;
  }

  .filter-reviews .toggle-filters {
    cursor: pointer;
  }

  .filter-reviews .flex {
    flex-direction: column-reverse;
  }

  .filter-reviews .filter {
    flex: none;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .rating-stars-average {
    font-size: 22px;
  }

  .reviews-summary .reviews-io-logo {
    height: 22px;
  }

  .filter-reviews .count-suffix {
    display: inline;
  }

  .grid-sizer,
  .masonry-brick {
    width: 100%;
  }

  .gutter-sizer {
    width: 0%;
  }

  .main-content {
    margin-right: 15px;
    margin-left: 15px;
  }

  .masonry-brick {
    margin-bottom: 30px;
  }

  .masonry-brick {
    margin-bottom: 30px;
  }
}

@media (max-width: 479.98px) {
  .see-all-btn {
    width: 100%;
  }
}
