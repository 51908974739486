/* unsubscribe.css */
.unsubscribe-search {
  font-size: 14px;
}

.unsubscribe-search h2 {
  font-size: 16px;
}

.unsubscribe-search form {
  background: #fff;
  padding: 10px;
  border-radius: 20px;
}

.unsubscribe-search form:before,
.unsubscribe-search form:after {
  content: " ";
  display: table;
}

.unsubscribe-search form:after {
  clear: both;
}

.unsubscribe-search ul.subscriptions {
  margin-bottom: 20px;
}

.unsubscribe-search .subscriptions li {
  padding: 5px 0;
}

.unsubscribe-search ul.list {
  margin-top: 10px;
  margin-bottom: 10px;
}

.unsubscribe-search ul.list li {
  background: var(--midlight-grey);
  padding: 5px 10px;
  margin-bottom: 5px;
  font-size: 12px;
  display: inline-block;
}

.unsubscribe-search .email-wrap p {
  margin: 0 0 10px 0;
  padding: 0;
}

.unsubscribe-search .email-input {
  position: relative;
  width: 100%;
  max-width: 300px;
  border: 1px solid var(--mid-grey);
  margin-bottom: 10px;
}

.unsubscribe-search .email {
  width: 100%;
  padding: 0 7px;
  height: 40px;
  line-height: 40px;
  border: none;
  border-radius: 5px;
}

.unsubscribe-search .email-input.invalid:after {
  content: "\f06a";
  color: var(--error-red);
  font: 14px/30px rgd-icons;
  position: absolute;
  bottom: 0px;
  right: -20px;
}

.unsubscribe-search .email-input.valid:after {
  content: "\f00c";
  color: var(--valid);
  font: 14px/30px rgd-icons;
  position: absolute;
  bottom: 0px;
  right: -20px;
}

.unsubscribe-search .validation {
  font-size: 14px;
  line-height: normal;
  color: var(--error-red);
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
}

.unsubscribe-search button {
  padding: 16px 32px;
}

@media (min-width: 640px) {
  .unsubscribe-search .selected li {
    float: left;
    width: 49%;
    margin-right: 2%;
  }

  .unsubscribe-search .selected li:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media (max-width: 639.98px) {
  .unsubscribe-search .selected li {
    float: left;
    width: 100%;
    margin-right: 0%;
  }
}
