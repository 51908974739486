/* Best deals */

.best-deals p {
  padding: 0;
}

.best-deals .deal-pill {
  position: absolute;
  right: 10px;
  top: 10px;
  background: var(--tertiary);
  color: #fff;
  border-radius: 20px;
  font-size: 13px;
  padding: 5px 10px;
  text-align: right;
  white-space: nowrap;
  font-weight: 700;
  z-index: 2;
}

.best-deals .stock:before {
  font-family: rgd-icons;
  content: "\f00c";
  font-size: 13px;
  font-weight: 400;
  margin-right: 5px;
}

.best-deals .deal-body {
  padding: 0 8px 16px;
}

.best-deals .deal-vehicle {
  text-align: center;
}

.best-deals .row.price {
  padding: 10px 0 0 0;
}

.best-deals .price .label {
  font-size: 16px;
  color: var(--primary);
  text-align: center;
}

.best-deals .price .value {
  font: 24px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 !important;
  text-align: center;
}

.best-deals .price .vat {
  font-size: 11px;
  color: var(--primary);
  text-align: center;
}

@media (min-width: 768px) {

  .best-deals .deal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 360px), (min-width: 576px) and (max-width: 767.98px) {
  .best-deals .value-rating-gauge-wrap.size4 {
    width: 72px;
    height: 60px;
  }

  .best-deals .value-rating-gauge-wrap.size4 .gauge-number {
    font-size: 24px;
  }

  .best-deals .price .value {
    font-size: 21px;
  }
}
