/* testimonials-carousel.css */

.testimonials-carousel {
  font-size: 16px;
  color: var(--primary);
  position: relative;
}

.testimonials-carousel .glide {
  margin-bottom: 20px;
}

.testimonials-carousel .title {
  text-align: center;
}

.testimonials-carousel .g-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
}

.testimonials-carousel .testimonials-image {
  margin: 0 auto;
  flex: 0 1 200px;
  max-width: 200px;
  min-width: 200px;
}

.testimonials-carousel .testimonials-image img {
  width: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 15px;
}

.edge .testimonials-carousel .testimonials-image img {
  pointer-events: none;
}

.testimonials-carousel .testimonials-c-paragraph {
  font-size: 16px;
  margin-bottom: 0px;
  flex: 0;
  align-items: center;
  justify-content: center;
}

.testimonials-carousel .testimonials-c-paragraph p {
  font-size: 16px;
}

.testimonials-carousel .testimonials-c-paragraph p:last-of-type {
  padding: 0;
  margin: 0;
}


.testimonials-carousel .testimonials-c-customer {
  margin-bottom: 10px;
}

.testimonials-carousel .testimonials-c-customer .name {
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
}

.testimonials-carousel .testimonials-c-customer-vehicle a {
  color: var(--secondary);
  font-weight: bold;
}

.testimonials-carousel .cta {
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.testimonials-carousel .cta a {
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 240px;
  margin: 10px auto;
}

.testimonials-carousel .g-slide.g-active {
  opacity: 1;
  z-index: 1;
}

.testimonials-carousel .g-active .testimonials-image,
.testimonials-carousel .g-active .testimonials-c-paragraph,
.testimonials-carousel .g-active .testimonials-c-customer-details {
  opacity: 1;
}

.testimonials-carousel .g-active .testimonials-c-paragraph {
  height: 100%;
}

@media (min-width:360px) {
  .testimonials-controls {
    top: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 5px;
    max-width: 480px;
    margin: 0 auto;
  }
}

@media (min-width: 576px) {
  .testimonials-carousel .testimonials-c-paragraph {
    opacity: 0;
    height: 0;
  }

  .testimonials-carousel .testimonials-c-customer-details {
    opacity: 0.25;
  }

  .testimonials-carousel .testimonials-image {
    opacity: 0.5;
  }
}
