/* trending-panel.css */
.trendingslider {
  border: none;
  background: none;
  margin: 0 auto;
  margin-bottom: 40px;
  position: relative;
  padding: 0;
}

.trendingslider .tns-outer {
  position: relative;
}

.trending-panel {
  width: calc(100% - 10px);
  margin: 0 auto;
  font-size: 16px;
}

.trending-panel .tab {
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 4px 16px 4px 16px;
  font-size: 14px;
  font-weight: 600;
  background: var(--tertiary);
  color: #fff;
}

.trending-panel .update {
  float: right;
  font-size: 12px;
  padding-top: 3px;
  margin-right: 5px;
  height: 24px;
  line-height: 24px;
}

#home .trending-panel .tab,
#home .trending-panel .update {
  display: none;
}

.trending-panel .trending-deal {
  clear: both;
  background: #fff;
  padding: 15px 15px 0;
  min-height: 286px;
}

.trending-panel .vehicle {
  display: inline-block;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-right: 10px;
  color: var(--primary);
  font-size: 18px;
  margin-bottom: 10px;
  min-width: 85px;
}

.trending-panel .percent {
  display: inline-block;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--secondary);
  font-size: 18px;
  margin-bottom: 10px;
}

.trending-panel .img-tab-wrap {
  clear: both;
  display: flex;
  justify-content: space-between;
}

.trending-panel .image-wrap {
  flex: 0 0 300px;
}

.trending-panel .image-wrap img {
  width: 300px;
  height: auto;
}

.edge .trending-panel .image-wrap img {
  pointer-events: none;
}

.trendingslider p {
  text-align: center;
  color: var(--middark-grey);
  font-size: 12px;
  padding-bottom: 20px;
}

.trending-panel .table-wrap {
  flex: 1 1 100%;
  max-width: 540px;
  display: flex;
  flex-direction: column;
  min-height: 200px;
}

.trending-panel .table-row {
  display: flex;
  justify-content: space-between;
  flex: 1 auto;
  align-items: flex-start;
  padding: 5px 0;
  max-height: 25%;
}

.trending-panel .table-row.saved-search {
  justify-content: flex-end;
}

.trending-panel .table-value {
  text-align: right;
  color: var(--tertiary);
  font-weight: bold;
  white-space: nowrap;
  flex: 1 1 120px;
  min-width: 120px;
}

.trendingslider button {
  background: none;
  border-radius: 0;
  text-align: inherit;
  display: block;
  padding: 0;
  color: inherit;
  float: none;
  transition: none;
}

.trendingslider button.trending {
  padding: 0 10px;
  height: 40px;
  line-height: 38px;
  display: inline-block;
  font-size: 14px;
  border-radius: 50px;
}

.trendingslider button.trending > i {
  font-size: 24px;
  margin-right: 5px;
  vertical-align: middle;
}

.trending-panel .cta {
  background: var(--secondary);
  color: #fff;
  width: 100%;
  transition: color 0.2s, background-color 0.2s;
  border-radius: 0 0 10px 10px;
}

.trending-panel .cta:hover,
.trending-panel .cta:focus {
  background: var(--primary);
}

/* before script initialises */
.trendingslider:not(.init) {
  margin-bottom: 86px;
}

.trendingslider:not(.init) li:not(:first-of-type) {
  display: none;
}

/* slider nav */
.trendingslider .tns-nav {
  width: 100%;
  text-align: center;
}

.trendingslider .tns-nav button {
  width: 14px;
  height: 14px;
  border: 2px solid var(--primary);
  cursor: pointer;
  box-shadow: none;
  border-radius: 20px;
  display: inline-block;
  margin: 16px 6px;
}

.trendingslider .tns-nav button.tns-nav-active {
  background: var(--primary);
  cursor: default;
}

.trendingslider .tns-controls {
  margin: 0;
  padding: 0;
}

.trendingslider .tns-controls button {
  text-decoration: none;
  display: block;
  width: 36px;
  height: 36px;
  margin: -18px 0 0;
  position: absolute;
  top: 50%;
  z-index: 8;
  overflow: hidden;
  cursor: pointer;
  color: #fff;
  transition: color 0.2s, background-color 0.2s;
}

.trendingslider .tns-controls button:before {
  font-family: "rgd-icons";
  font-size: 54px;
  display: inline-block;
  color: var(--primary);
  line-height: 36px;
}

.trendingslider .tns-controls button[data-controls="prev"] {
  left: -46px;
  text-align: center;
}

.trendingslider .tns-controls button[data-controls="prev"]:before {
  content: "\f104";
  margin-left: -2px;
}

.trendingslider .tns-controls button[data-controls="next"] {
  right: -46px;
  text-align: center;
}

.trendingslider .tns-controls button[data-controls="next"]:before {
  content: "\f105";
  margin-left: 2px;
}

.trendingslider .tns-controls button[data-controls="prev"]:hover:before,
.trendingslider .tns-controls button[data-controls="next"]:hover:before {
  color: var(--tertiary);
}

@media (max-width: 1115.98px) {
  .trending-panel {
    width: calc(100% - 92px);
  }

  .trendingslider .tns-controls button[data-controls="prev"] {
    left: 0;
  }

  .trendingslider .tns-controls button[data-controls="next"] {
    right: 0;
  }
}

@media (max-width: 767.98px) {
  .trending-panel {
    width: calc(100% - 10px);
    margin: 0 auto;
  }

  .trending-panel .image-wrap {
    max-width: 100%;
  }

  .trending-panel .image-wrap img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 599.98px) {
  .trending-panel .vehicle {
    width: 100%;
    margin: 0;
  }

  .trending-panel .img-tab-wrap {
    flex-wrap: wrap;
  }

  .trending-panel table tr {
    height: 55px;
  }

  .trending-panel .image-wrap {
    margin: 0 auto;
  }

  .trending-panel .table-wrap {
    margin-bottom: 10px;
  }

  .trending-panel .table-row.saved-search {
    justify-content: flex-start;
  }
}

@media (max-width: 359.98px) {
  .trending-panel .vehicle {
    letter-spacing: -0.02em;
  }
}
