/*services-carousel.css*/

.services-carousel {
  text-align: center;
}

.services-carousel .title {
  font-size: 30px;
}

.services-carousel .services {
  z-index: 1;
}

.services-carousel .center {
  text-align: center;
}

.services-carousel .g-slider {
  margin-bottom: 0;
}

.services-carousel h2 {
  color: #fff;
}

.services-carousel p {
  font-size: 14px;
  color: #fff;
}

.services-carousel .image-wrapper {
  margin-bottom: 30px;
  display: none;
}

.services-carousel .image-wrapper img {
  margin: 0 auto;
  width: 100%;
  height: auto;
  max-width: 360px;
}

.edge .services-carousel .image-wrapper img {
  pointer-events: none;
}

.services-carousel .logo {
  width: auto;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
}

.services-carousel .inner-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.services-carousel .flex-container {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 40px;
}

.services-carousel .col2 {
  padding: 0;
  position: relative;
  z-index: 1;
}

.services-carousel .col2 .button {
  margin: 0 auto 10px;
  position: relative;
}

.services-carousel .icon-list {
  margin: 10px 0 20px;
  font-size: 14px;
}

.services-carousel .icon-list li {
  margin-bottom: 20px;
  text-align: left;
}

.services-carousel .icon-list i {
  font-size: 32px;
  display: inline-block;
  width: 32px;
  height: auto;
  vertical-align: top;
  margin-right: 5px;
  margin-top: -5px;
  color: inherit;
}

.services-carousel .icon-list .text-block {
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 48px);
}

.services-carousel .ldc:after {
  color: transparent;
}

.services-carousel .reviewstext,
.services-carousel .trustpilotimage {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}

.services-carousel .reviewsio-image {
  display: inline-block;
  vertical-align: middle;
}

.services-carousel .reviewsio-image img {
  width: 120px;
  height: auto;
}

.services-carousel .services {
  background-color: var(--primary);
  color: #fff;
}

.services-carousel .services .image-wrapper img {
  width: 200px;
  height: auto;
  max-width: 200px;
}

.edge .services-carousel .services .image-wrapper img {
  pointer-events: none;
}

.services-carousel .services .cta {
  width: 250px;
}

.services-carousel .motorway {
  background-color: #fef200;
  color: #1d1d1b;
}

.services-carousel .motorway h2,
.services-carousel .motorway p {
  color: #1d1d1b;
}

.services-carousel .motorway .logo {
  height: 40px;
}

.services-carousel .motorway .image-wrapper img {
  max-width: 240px;
  transform: rotate(-30deg);
}

.services-carousel .motorway .cta {
  background-color: #0460cc;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 220px;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.services-carousel .motorway .cta:hover {
  background-color: #1b7fdd;
}

.services-carousel .motorway .cta:hover svg {
  animation: chevronRightSlide .7s;
}

@keyframes chevronRightSlide {
  from {
    transform: translate3d(0, 0, 0);
  }

  20% {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  51% {
    transform: translate3d(0, 0, 0);
  }

  80% {
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.services-carousel .motorway .flex-container:before {
  background-color: #fff;
  content: "";
  display: block;
  height: 200%;
  right: -30px;
  min-height: 1000px;
  position: absolute;
  width: 150px;
  bottom: 50%;
  transform: rotate(-30deg);
  transform-origin: bottom right;
  z-index: 0;
}

.services-carousel .motorway .flex-container:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 200%;
  right: -30px;
  min-height: 1000px;
  position: absolute;
  width: 150px;
  top: 50%;
  transform: rotate(30deg);
  transform-origin: top right;
  z-index: 0;
}

.motorway-icon-right {
  margin-left: 4px;
  height: 24px;
  fill: #fff;
  transition: fill .2s linear,stroke .2s linear;
}

.services-carousel .motoreasy {
  color: #fff;
}

.services-carousel .motoreasy .cta {
  background-color: #dc453a;
  color: #fff;
  border-radius: 50px;
  margin-bottom: 10px;
  width: 220px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  border: 2px solid #fff;
}

.services-carousel .motoreasy .cta:hover,
.services-carousel .motoreasy .cta:focus {
  background-color: #c12d22
}

.services-carousel .motoreasy {
  background-image: url("/media/ui/motoreasy-bg.webp");
  background-size: cover;
}


@media (min-width: 600px) {
  .services-carousel {
    text-align: left;
  }

  .services-carousel p {
    font-size: 16px;
  }

  .services-carousel .image-wrapper {
    display: block;
  }

  .services-carousel .inner-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .services-carousel .inner-flex > div {
    flex: 1 1 50%;
  }

  .services-carousel .col2:first-of-type {
    padding-right: 20px;
  }

  .services-carousel .services {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center left;
  }

  .services-carousel .motorway .flex-container:before {
    right: 0px;
  }

  .services-carousel .motorway .flex-container:after {
    right: 0px;
  }
}

@media (min-width: 992px) {
  .services-carousel .inner-flex {
    flex-direction: row;
  }
}

@media (min-width: 600px) and (max-width: 767.98px) {
  .services-carousel .services {
    background-image: url("/media/ui/home-services-768.webp");
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .services-carousel .services {
    background-image: url("/media/ui/home-services-992.webp");
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .services-carousel .services {
    background-image: url("/media/ui/home-services-1200.webp");
  }
}

@media (min-width: 1200px) {
  .services-carousel .services {
    background-image: url("/media/ui/home-services-1920.webp");
  }
}
