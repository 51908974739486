/* faqs.css */

.info-summary {
  background: #fff;
  text-align: center;
  border-radius: 15px;
  padding: 20px 15px;
  margin-bottom: 40px;
}

#home .info-summary,
.info-summary.wrapper,
.info-summary.wrapper-1400 {
  margin-bottom: 0;
  padding: 0;
}

.info-summary .title {
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  padding-top: 10px;
  margin-bottom: 10px;
  color: var(--primary);
}

.info-summary-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.info-step {
  flex: 1 1 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-step .image-wrap {
  margin-bottom: 20px;
}

.info-step .image-wrap img {
  width: 140px;
  height: 84px;
  max-width: 140px;
  display: block;
  margin: 0 auto;
}

.edge .info-step .image-wrap img {
  pointer-events: none;
}

.info-step .sub-heading {
  font-size: 18px;
  margin-bottom: 20px;
  min-height: 46px;
}

.info-step .info {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5em;
}

.faqs, .info-list {
  margin-bottom: 40px;
  background: #fff;
  padding: 20px 15px;
  border-radius: 15px;
}

.faqs a, .info-list a {
  color: var(--secondary);
}

.faqs h2, .info-list h2 {
  color: var(--primary);
  font-size: 24px;
}

.faqs h3, .info-list h3 {
  color: var(--primary);
  font-size: 24px;
}

.info-list h2, .info-list h3 {
  text-align: center;
}

.faq-list {
  width: 100%;
  text-align: left;
}

.faq-list .faq-question,
.faq-list h3.faq-question,
.faq-list h4.faq-question {
  background: #fff;
  padding: 15px 45px 15px 15px;
  cursor: pointer;
  font-weight: bold;
  border-top: 1px dotted var(--border-grey);
  position: relative;
  font-size: 16px;
  color: var(--black);
  font-family: "Work Sans", Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: left;
}

.faq-list .faq-question:first-of-type {
  border-top: none;
}

.faq-list .faq-question:after {
  position: absolute;
  top: 15px;
  right: 15px;
  font-family: rgd-icons;
  content: "\f107";
  vertical-align: top;
  font-size: 32px;
  line-height: 20px;
  display: block;
  color: var(--tertiary);
  transition: transform 0.2s;
}

.faq-list .faq-question.expanded:after {
  transform: rotate(180deg);
}

.faq-list .faq-answer {
  text-align: left;
  line-height: 1.5em;
  max-width: 769px;
  padding: 0 15px;
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq-list .faq-answer > ul > li {
  position: relative;
  margin-left: 30px;
}

.faq-list .faq-answer > ul > li:before {
  position: absolute;
  font-family: rgd-icons;
  content: "\f105";
  margin-left: -30px;
  color: var(--tertiary);
  font-size: 36px;
  margin-top: 0;
}

.faq-list p {
  font-size: 16px;
}

.info-list .icon-list {
  width: 100%;
  display: block;
  margin: 20px 0;
}

.info-list .icon-list li {
  min-height: 3em;
  text-align: left;
  padding: 15px;
}

.info-list .icon-list i {
  font-size: 32px;
  display: inline-block;
  width: 32px;
  height: auto;
  vertical-align: top;
  margin-right: 10px;
}

.info-list .icon-list i.lg {
  font-size: 48px;
  width: 48px;
}

.info-list .icon-list > li > .text-block {
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 50px);
}

.info-list .icon-list > li > .text-block.lg {
  display: inline-block;
  margin-bottom: 10px;
  width: calc(100% - 66px);
}

/* local pages */
.local-facts-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.local-facts,
.local-faqs {
  padding: 0 20px;
  flex: 1 1 100%;
}

.local-facts {
  margin-bottom: 40px;
}

.local-facts p {
  font-size: 16px;
}

.local-facts li {
  position: relative;
  margin-left: 30px;
  margin-bottom: 10px;
  line-height: 1.5em;
  display: list-item;
}

.local-facts > ul > li::before {
  position: absolute;
  font-family: rgd-icons;
  content: "\f105";
  margin-left: -30px;
  color: var(--tertiary);
  font-size: 36px;
  margin-top: 0;
}

.local-faqs .faq-list .faq-question {
  padding: 15px 45px 15px 0;
}

@media (min-width: 576px) {
  .info-step {
    flex: 1 1 33.3333%;
    min-width: 33.3333%;
  }
}

@media (min-width: 576px) and (max-width: 600px) {
  .info-step .info {
    font-size: 14px;
  }
}

@media (min-width: 768px) {

  .faqs h2, .faqs h3 {
    text-align: center;
  }

  .local-facts-wrapper > .local-facts,
  .local-facts-wrapper > .local-faqs {
    flex: 1 1 50%;
  }

  .local-facts {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .info-step .sub-heading {
    min-height: unset;
  }
}
