/* search panel home */

.search-panel-home {
  margin: -90px auto 40px;
  background: rgba(255,255,255,1);
  width: 992px;
  max-width: 94%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: var(--primary);
  border-radius: 15px;
  font-size: 16px;
  padding: 20px;
}

.search-panel-home h1 {
  text-align: center;
  font-size: 32px;
  color: var(--primary);
  margin: 0 0 10px 0;
}

.search-panel-home .search-options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.search-panel-home .dropdown:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 18px;
  right: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--primary);
}

.search-panel-home select {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  font-family: Work Sans,Gotham,Helvetica Neue,Helvetica,Arial,sans-serif;
  appearance: none;
  height: 40px;
  position: relative;
  padding: 5px 20px 5px 8px;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid var(--mid-grey);
  color: var(--primary);
  background: #fff;
  text-overflow: ellipsis;
  text-align: center;
  text-align-last: center;
}

.search-panel-home .dropdown {
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  flex: 1 1 calc(50% - 10px);
  margin-bottom: 10px;
}

.search-panel-home .dropdown.col2 {
  flex: 1 1 120%;
}

.search-panel-home button,
.search-panel-home .button {
  height: 50px;
  font-size: 16px;
  line-height: 50px;
  padding: 0 10px;
  margin: 0 5px;
  flex: 1 1 100%;
}

.search-panel-home.search-button {
  flex: 0 1 auto;
}

.search-panel-home .search-button:before {
  content: "\f002";
  font-family: rgd-icons;
  margin-right: 5px;
  font-weight: normal;
  font-size: 16px;
}

.search-panel-fydc-home {
  margin: 0 auto;
  background: rgba(255, 255, 255, 1);
  width: 569px;
  max-width: 94%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: var(--primary);
  padding: 20px 10px;
  border-radius: 15px;
}

.search-panel-fydc-home .fydc-link {
  text-align: center;
  color: var(--black);
  font-size: 16px;
}

.search-panel-fydc-home .fydc-link p {
  color: var(--black);
  font-size: 16px;
  padding: 0;
}

.search-panel-fydc-home .fydc-link .button {
  max-width: 260px;
  padding: 11px 16px;
  margin: 10px auto 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  font-size: 18px;
  padding: 0;
  height: 50px;
  line-height: 50px;
  background: var(--primary);
  justify-content: center;
}

.search-panel-fydc-home .fydc-link .button:hover {
  background: var(--dark-primary);
}

.search-panel-fydc-home i {
  margin-right: 5px;
  font-size: 24px;
  transform: rotate(-10deg);
  margin-top: 1px;
}

.home-top-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  text-align: center;
  justify-content: center;
}

.home-top-links > a {
  flex: 1 1 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: var(--primary);
  padding: 20px 10px;
  border-radius: 15px;
  text-decoration: none;
  transition: transform .1s;
}

.home-top-links i {
  font-size: 32px;
  margin-bottom: 10px;
  color: var(--tertiary);
}

.home-top-links .title {
  color: var(--primary);
  font-family: Leasing, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
}

.home-top-links p {
 font-size: 14px;
 color: var(--black);
 margin: 0;
 padding: 0;
}

@media (max-width: 575.98px) {
  .home-top-links > a {
    display: flex;
    flex-direction: row;
    text-align: left;
    max-width: 480px;
    padding: 10px 20px;
  }

  .home-top-links i {
    margin-right: 10px;
    flex: 0 0 40px;
  }

  .home-top-links > a > div {
    padding-right: 40px;
    position: relative;
    flex: 1 1 100%;
  }

  .home-top-links > a > div:after {
    position: absolute;
    right: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    content: "\f105";
    font-family: rgd-icons;
    text-align: center;
    transform: translateY(-50%);
    font-size: 24px;
  }
}

  @media (min-width: 576px) {
    .home-top-links > a {
      flex: 0 0 calc(33.3333% - 20px);
      max-width: 230px;
    }

    .home-top-links > a:hover {
      transform: scale(1.04);
    }
  }
  /* media queries */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .search-panel-home .dropdown {
      flex: 0 0 calc(50% - 10px);
      margin-bottom: 10px;
    }

    .search-panel-home .dropdown.col2 {
      flex: 0 0 calc(50% - 10px);
    }
  }

  @media (min-width: 768px) {
    .search-panel-home {
      margin: -170px auto 40px;
      background: rgba(255,255,255,0.8);
      background: -moz-linear-gradient(left, rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255,255,255,0.8) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255,255,255,0.8) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .search-panel-home .dropdown {
      flex: 1 1 80px;
      margin-bottom: 0;
    }

    .search-panel-home .dropdown.col2 {
      flex: 1 1 165px;
    }

    .search-panel-home button,
    .search-panel-home .button {
      flex: 0 0 auto;
      height: 40px;
      line-height: 40px;
    }
  }
