/* deals-newsletter-signup.css */

.deal-subscribe {
  max-width: 100%;
  margin: 40px auto;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  background-image: url('/media/ui/circle-tl.svg'), url('/media/ui/circle-br.svg');
  background-position-x: left, right;
  background-position-y: top, bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 80px, 80px;
  line-height: normal;
  width: 100%;
  background-color: #fff;
}

.w992 .deal-subscribe {
  width: 992px;
}

.grey-bg .deal-subscribe {
  background-color: var(--main-bg);
}


.deal-subscribe .block1 {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

.deal-subscribe .sub-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.deal-subscribe .title {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.5px;
  color: var(--primary);
  padding-bottom: 0.25em;
  font-family: Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0;
}

.deal-subscribe .block2 {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  align-items: center;
}

.deal-subscribe .input {
  position: relative;
  margin: 10px 0;
  flex: 1 1 auto;
  width: 100%;
  max-width: 400px;
}

.deal-subscribe .forminput {
  border-radius: 5px !important;
}

.deal-subscribe .cta {
  width: 100%;
  max-width: 150px;
  margin: 10px 0 10px;
  order: 2;
}

.deal-subscribe .cta button {
  margin: 0 auto;
  width: 100%;
}

.deal-subscribe .cta button:disabled {
  background-color: var(--secondary) !important;
  color: #fff;
}

.deal-subscribe .news-mail-icon {
  transform: rotate(-10deg);
}

.deal-subscribe .news-heart-icon {
  transform: rotate(10deg);
}

.deal-subscribe .news-heart-icon i,
.deal-subscribe .news-mail-icon i {
  font-size: 56px;
}

.deal-subscribe .ch-validation-icon {
  line-height: 46px;
  height: 48px;
}

.deal-subscribe .override-email {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  min-height: 25px;
  margin: 10px 0;
  background: rgba(255,255,255,0.75);
  text-align: left;
  padding: 10px 10px 10px 45px;
  border: 1px solid var(--error-red);
  min-height: 45px;
  border-radius: 5px;
  order: 1;
}

.deal-subscribe .override-email label {
  display: inline-block;
}

.deal-subscribe .override-email input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.deal-subscribe .override-email .checkmark {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid var(--border-grey);
  border-radius: 3px;
}

/* Style the checkmark/indicator */
.deal-subscribe .override-email .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid var(--tertiary);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.deal-subscribe .override-email input:checked ~ .checkmark:after {
  display: block;
}

/*media queries*/
@media (max-width: 359.98px) {
  .deal-subscribe .title {
    font-size: 21px;
  }
}

@media (max-width: 767.98px) {
  .deal-subscribe .iconblock {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }

  .deal-subscribe .news-heart-icon i,
  .deal-subscribe .news-mail-icon i {
    font-size: 56px;
  }
}

@media (min-width: 768px) {
  .deal-subscribe {
    background-image: url('/media/ui/circle-tl.svg'), url('/media/ui/circle-br.svg');
    background-position-x: left, right;
    background-position-y: top, bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: 100px, 100px;
  }

  .deal-subscribe .title {
    font-size: 24px;
  }

  .deal-subscribe .block2 {
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .deal-subscribe .input {
    order: 1;
    width: auto;
    max-width: none;
  }

  .deal-subscribe .forminput {
    max-width: none;
    border-radius: 5px 0 0 5px !important;
    border-right: none;
  }

  .deal-subscribe .cta button {
    margin: 0;
    border-radius: 0 5px 5px 0;
    padding: 0 16px;
    height: 48px;
    line-height: 48px;
    width: 100%;
  }

  .deal-subscribe .news-mail-icon {
    position: absolute;
    top: 50px;
    left: 16.6666%;
  }

  .deal-subscribe .news-heart-icon {
    position: absolute;
    top: 50px;
    right: 16.6666%;
  }

  .deal-subscribes .deal-subscribe .override-email {
    order: 2;
  }
}
